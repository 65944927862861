import ContentfulImage from '@/components/contentful-image';
import { JobItemIC, JobsIC } from '@/lib/interface-types';
import PlusGreenImage from '@/public/icons/add-plus-green.svg';
import PlusImage from '@/public/icons/add-plus-icon.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import JobForm from '../job-form';
import PersonBanner from '../person-banner';
import JobItem from './job-item';
import SelectBox from './select-box';

const locations = [
  'All Locations',
  'Zrenjanin',
  'Novi Sad',
  'Porto',
  'Lucerne',
  'Munich',
  'Zurich',
  'Belgrade',
  'Rhine Valley',
  'Dornbirn',
  'Bern',
  'Lisbon'
];

const positions = [
  'All Roles',
  'IT',
  'Project Management',
  'Quality Assurance',
  'Talent Acquisition',
  'UX/UI',
  'Business Administration',
  'Business Development'
];

const Jobs = ({ count, bannerData }: JobsIC) => {
  const [location, setLocation] = useState('All Locations');
  const [role, setRole] = useState('All Roles');
  const [allJobs, setAllJobs] = useState<JobItemIC[]>([]);
  const [dataBanner, setDataBanner] = useState(null);
  const [moreJobs, setMoreJobs] = useState(6);
  const [filteredJobs, setFilteredJobs] = useState<JobItemIC[]>([]);
  const [showForm, setShowForm] = useState(false);

  const { t } = useTranslation();

  const jobLocation = location !== 'All Locations' ? location : '';
  const jobRole = role !== 'All Roles' ? role : '';

  const hasMoreData = filteredJobs.length > moreJobs;

  const filterHandler = (data: JobItemIC[]) => {
    let filteredData = data;

    if (jobLocation) {
      filteredData = filteredData.filter((job) =>
        job.locations.some((l) => l.name.includes(jobLocation))
      );
    }

    if (jobRole)
      filteredData = filteredData.filter((job) =>
        job.tags?.some((tag) => tag.includes(`Career ${role}`))
      );

    setFilteredJobs(filteredData);
  };

  useEffect(() => {
    const fetchJobs = async () => {
      const url = `https://consulteer1.recruitee.com/api/offers/`;
      const options = {
        method: 'GET',
        headers: {
          accept: 'application/json'
        }
      };
      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const result = await response.json();
        setAllJobs(result.offers);
        setMoreJobs(6);
      } catch (error) {
        return <div>No data</div>;
      }
    };

    !allJobs.length && fetchJobs();
    filterHandler(allJobs);
  }, [role, location]);

  useEffect(() => {
    const sortedData = allJobs?.sort((a: any, b: any) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return dateB.getTime() - dateA.getTime();
    });
    setFilteredJobs(sortedData);
  }, [allJobs]);

  useEffect(() => {
    const locationExists = bannerData?.some((banner: any) =>
      banner.locations?.includes(location)
    );

    if (locationExists) {
      const matchingBanners = bannerData.filter((banner: any) =>
        banner.locations?.includes(location)
      );

      setDataBanner(matchingBanners[0]);
    }
  }, [bannerData, location]);

  return (
    <div className="Jobs mt-20">
      <div className="lg:flex lg:justify-between lg:items-center lg:my-8">
        <h2>
          {t('OpenPosition')} <span className="text-brand-green">{count}</span>
        </h2>
        <div className="flex justify-between lg:justify-end sm:justify-start my-4 lg:my-0">
          <SelectBox
            onChange={(position) => setRole(position)}
            items={positions}
            className="mr-5 w-[230px]"
          />
          <SelectBox
            onChange={(location) => setLocation(location)}
            items={locations}
            className="ml-5 w-[200px] text-xl"
          />
        </div>
      </div>

      {!!filteredJobs.length && (
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14"
          id="allJobs"
        >
          {filteredJobs.slice(0, moreJobs).map((job: JobItemIC) => (
            <div
              key={uuid()}
              className="hover:outline-[#3cd52e] hover:outline hover:outline-[4px] shadow-[-2px_-2px_8px_rgba(209,209,209,0.5)] h-[380px] bg-white flex flex-col"
            >
              <JobItem item={job} />
            </div>
          ))}
        </div>
      )}
      {!filteredJobs.length && (
        <h3 className="text-center">
          {t('topCareers.no_jobs_for_current_positions')}
        </h3>
      )}

      {hasMoreData && (
        <div
          className="flex items-center justify-items-center	 cursor-pointer font-bold mt-10 group w-fit"
          onClick={() => setMoreJobs(moreJobs + 6)}
          aria-hidden="true"
        >
          <p className="uppercase show-more mt-2 mr-2 group-hover:text-brand-green">
            {t('topCareers.show_more_jobs')}
          </p>
          <div className="hidden group-hover:flex">
            <ContentfulImage
              src={PlusGreenImage}
              width={24}
              height={24}
              alt="Load more"
            />
          </div>
          <div className="group-hover:hidden">
            <ContentfulImage
              src={PlusImage}
              width={24}
              height={24}
              alt="Load more"
            />
          </div>
        </div>
      )}

      <button
        className="green-button ml-2 mt-10 group"
        type="button"
        onClick={() => setShowForm(true)}
      >
        <p className="group-hover:text-brand-green">
          {t('topCareers.quick_apply')}
        </p>
      </button>
      <div className={`${showForm ? 'visable' : 'invisible'}`}>
        <JobForm
          id="6275578027cd7"
          onClose={() => setShowForm(false)}
          slug="spontaneous-application"
          questions={allJobs[0]?.open_questions}
        />
      </div>

      {dataBanner && (
        <div>
          <PersonBanner data={dataBanner} sectionbaner={false} />
        </div>
      )}
    </div>
  );
};

export default Jobs;
