import { QuoteSectionIC, SectionIC } from '@/lib/interface-types';
import QuoteIcon from '@/public/icons/quote.svg';
import Image from 'next/image';

export interface IQuote {
  section?: SectionIC;
  quoteText?: string;
}

export const Quote = ({ section }: QuoteSectionIC) => {
  const { quoteText, author, authorTitle } = section;
  return (
    <div className="Quote px-0 py-6 sm:py-8 lg:py-0 md:px-20 pb-10 mt-10 lg:mt-20 lg:pb-20">
      <Image
        src={QuoteIcon}
        alt="quote"
        width={74}
        height={50}
        className="pb-6"
      />
      <h2>{quoteText}</h2>
      {author && authorTitle && (
        <h5 className="mt-6">
          <span className="font-bold">{author}</span>
          {'\u00A0'}|{'\u00A0'}
          <span>{authorTitle}</span>
        </h5>
      )}
    </div>
  );
};
